import { useContext } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import PlusIcon from 'src/assets/icons/plus-icon.svg?react';
import { DataGroup } from 'src/entities/dicts';
import { TermSheetContext } from './TermSheetContext';

type Props = ButtonProps & {
  group: DataGroup;
  canManage?: boolean;
};

function TermSheetCreateBtn({ group, canManage, ...rest }: Props) {
  const { showCreateOfferModal } = useContext(TermSheetContext);

  return group === DataGroup.loanApps && canManage ? (
    <Button
      size="small"
      onClick={() => showCreateOfferModal()}
      startIcon={<SvgIcon component={PlusIcon} />}
      {...rest}
    >
      Create term sheet
    </Button>
  ) : null;
}

export default TermSheetCreateBtn;
