import { FormProvider } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import { getDefaultFormValues, useForm } from 'src/helpers';
import {
  OfferValuesFormParams,
  OfferValue,
  MakeOfferFormField,
  OfferItem,
} from 'src/entities/offers';
import ModalDialog, { ModalCloseHandler } from '../ModalDialog';
import { offerFormValidation, offerValuesRowValidation } from './formData';
import FormArray, { FormArrayRowAddBtn } from '../FormArray';
import TermSheetFormRow from './TermSheetFormRow';
import { MAX_FORM_ROWS } from './constants';

type Props = {
  loading?: boolean;
  open: boolean;
  onClose: ModalCloseHandler;
  onSubmit: (values: OfferValuesFormParams) => void;
  values?: OfferValue[];
  title: string;
};

function TermSheetFormModal({
  loading,
  open,
  onClose,
  onSubmit,
  title,
  values = [],
}: Props) {
  const formProps = useForm<OfferValuesFormParams>({
    validation: offerFormValidation,
    defaultValues: { values },
  });

  return (
    <ModalDialog
      PaperProps={{ sx: { minHeight: '25rem' } }}
      contentProps={{ sx: { gap: 2 } }}
      onConfirm={formProps.handleSubmit(onSubmit)}
      confirmBtnText="Submit"
      confirmBtnLoading={loading}
      onClose={onClose}
      open={open}
      title={title}
      showConfirmBtn
    >
      <Typography variant="body1" color="textSecondary">
        Please enter proposed terms
      </Typography>
      <FormProvider {...formProps}>
        <FormArray<OfferItem>
          name={MakeOfferFormField.Values}
          rowDefaultValues={
            getDefaultFormValues(offerValuesRowValidation) as OfferValue
          }
          rowComponent={TermSheetFormRow}
          addRowComponent={FormArrayRowAddBtn}
          addRowProps={{ label: 'Add value' }}
          maxRows={MAX_FORM_ROWS}
        />
      </FormProvider>
    </ModalDialog>
  );
}

export default TermSheetFormModal;
