import { useCallback, useContext } from 'react';
import Typography from '@mui/material/Typography';
import Stack, { StackProps } from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import ArrowRightIcon from 'src/assets/icons/arrow-right-icon.svg?react';
import { getAllowedOfferActions, OfferItem } from 'src/entities/offers';
import { DataGroup } from 'src/entities/dicts';
import ModalDialog, { ModalCloseHandler } from '../ModalDialog';
import TermSheetAccStatus from './TermSheetAccStatus';
import { DataListCell } from '../DataList';
import { TermSheetContext } from './TermSheetContext';

type Props = {
  group: DataGroup;
  item: OfferItem;
  onClose: ModalCloseHandler;
  open: boolean;
  canManage?: boolean;
};

const valueStyle: StackProps = {
  direction: 'row',
  gap: 1,
  alignItems: 'center',
  whiteSpace: 'pre-wrap',
};

function TermSheetDetailsModal({
  group,
  item,
  open,
  onClose,
  canManage,
}: Props) {
  const { values, acceptanceStatusCode } = item;
  const { showEditOfferModal } = useContext(TermSheetContext);
  const { canEdit } = getAllowedOfferActions(item, group, canManage);

  const onEdit = useCallback(() => {
    onClose();
    showEditOfferModal(item);
  }, [showEditOfferModal, item, onClose]);

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      onConfirm={onEdit}
      showConfirmBtn={canEdit}
      cancelBtnText="Close"
      confirmBtnText="Edit Term Sheet"
      title="Summary of Principal Terms and Conditions"
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography color="text.secondary">Acceptance status</Typography>
        <TermSheetAccStatus value={acceptanceStatusCode} />
      </Stack>
      <Typography variant="subtitle1">Proposed terms</Typography>
      <Stack gap={2} mb={1}>
        {values.map(({ id, offerKey, offerValue, parentValue }) => {
          const parentDisplayVal = parentValue !== offerValue && parentValue;
          return (
            <DataListCell
              key={id}
              sx={{ bgcolor: 'background.default', p: 1, borderRadius: 2 }}
              primary={
                <Stack {...valueStyle}>
                  {parentDisplayVal}
                  {!!parentDisplayVal && (
                    <SvgIcon
                      component={ArrowRightIcon}
                      fontSize="small"
                      sx={{ mb: 0.2 }}
                    />
                  )}
                  {offerValue}
                </Stack>
              }
              secondary={offerKey}
            />
          );
        })}
      </Stack>
    </ModalDialog>
  );
}

export default TermSheetDetailsModal;
