import { HorizontalTableRowItem } from 'src/components/HorizontalTable';
import { DataGroup } from '../dicts';

export enum OfferInitiatorType {
  Borrower = 'BORROWER',
  Creditor = 'CREDITOR',
}

export enum OfferVerificationStatus {
  UnderReview = 'UNDER_REVIEW',
  Verified = 'VERIFIED',
}

export enum OfferAcceptanceStatus {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  CounterOffered = 'COUNTER_OFFERED',
  ImplicitlyAccepted = 'IMPLICITLY_ACCEPTED',
  NotSelected = 'NOT_SELECTED',
  UnderReview = 'UNDER_REVIEW',
}

export type OfferValue = {
  id: string;
  offerKey: string;
  offerKeyCode: string;
  offerValue: string;
  parentValue?: string;
};

export type OfferItem = {
  id: number;
  parentId?: number;
  loanApplicationId: number;
  opportunityId?: number;
  initiatorType: OfferInitiatorType;
  borrowerCompanyFullName?: string;
  creditorCompanyFullName?: string;
  verificationStatusCode: OfferVerificationStatus;
  acceptanceStatusCode: OfferAcceptanceStatus;
  values: OfferValue[];
  parent?: OfferItem;
};

export enum OfferCompareField {
  AcceptanceStatusCode = 'acceptanceStatusCode',
  CompanyName = 'companyName',
  InitiatorType = 'initiatorType',
}

export enum MakeOfferFormField {
  Values = 'values',
  OfferKey = 'offerKey',
  OfferKeyCode = 'offerKeyCode',
  OfferValue = 'offerValue',
}

export type OffersTermSheet = {
  activeOffers: OfferItem[];
  allOffersCancelled?: boolean;
};

export type OfferValuesFormParams = {
  values: OfferValue[];
};

export type OfferBaseUrlParams = {
  group: DataGroup;
  id: number;
};

export type OfferUrlParams = OfferBaseUrlParams & {
  opportunityId?: number;
  termSheetId?: number;
};

export type OfferReqParams = OfferUrlParams & OfferValuesFormParams;

export type OfferCompareItem = Omit<OfferItem, 'values'> &
  Record<string, string>;

export type OfferCompareData = {
  items?: OfferCompareItem[];
  values: HorizontalTableRowItem<OfferCompareItem>[];
};
