import { PagingParams } from 'src/components/Paging';
import { LoanAppPayload } from '../loanApps';
import { Currency } from '../currency';
import { PartyType } from '../customer';
import { BorrowerType, CollateralType, LoanType, Sector } from '../dicts';

export enum FundingOppStatus {
  New = 'NEW',
  DueDiligence = 'DUE_DILIGENCE',
  Funded = 'FUNDED',
  Gone = 'GONE',
  Declined = 'DECLINED',
}

export enum FundingOppNDAStatus {
  Signed = 'SIGNED',
  Required = 'REQUIRED',
  NotRequired = 'NOT_REQUIRED',
}

export enum FundingOppItemField {
  AdditionalInfo = 'additionalInfo',
  BorrowerTypeCode = 'borrowerTypeCode',
  BriefDescriptionLink = 'briefDescriptionLink',
  LoanAmount = 'loanAmount',
  LoanTerm = 'loanTerm',
  LoanTypeCode = 'loanTypeCode',
  RegionCode = 'regionCode',
  SectorCode = 'sectorCode',
}

export enum FundingOppBorrowerInfoField {
  Description = 'description',
  FullCompanyName = 'fullCompanyName',
  RegistrationNumber = 'registrationNumber',
  Website = 'website',
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  City = 'city',
  Country = 'country',
  PostalCode = 'postalCode',
  StateProvince = 'stateProvince',
}

export type FundingOppOfficeAddress = {
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  id: number;
  postalCode: string;
  stateProvince: string;
};

export type FundingOppBorrower = {
  description: string;
  fullCompanyName: string;
  id: number;
  legalFormCode: string;
  partyType: PartyType;
  primaryBusinessAddress: FundingOppOfficeAddress;
  registeredOfficeAddress: FundingOppOfficeAddress;
  website: string;
};

export type FundingOppLoanApp = Partial<LoanAppPayload> & {
  id: number;
  borrower: FundingOppBorrower;
  rawdata: LoanAppPayload;
};

export type FundingOppItem = {
  id: number;
  briefDescriptionLink: string;
  fullDetailsAvailable: boolean;
  loanApplicationId: number;
  loanApplication?: FundingOppLoanApp;
  ndaFromCreditorDocId?: number;
  ndaStatusCode: FundingOppNDAStatus;
  targetedClosure: string;
  additionalInfo?: string;
  borrowerTypeCode: BorrowerType;
  createdDate: string;
  currency: Currency;
  collateralTypes: CollateralType[];
  loanAmount: number;
  loanTerm: number;
  loanTypeCode: LoanType;
  irrFrom: number;
  irrTo: number;
  irrRange?: number[];
  sectorCode: Sector;
  statusCode: FundingOppStatus;
  regionCode: string;
};

export type FundingOppsListParams = PagingParams & {
  status?: FundingOppStatus[];
};

export type FundingOppUploadNdaParams = {
  id?: number;
  file?: File;
};
