import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, QueryRoot, ReqMethod, Response } from 'src/services';
import { generatePath, mapObjToFormData } from 'src/utils';
import {
  FUNDING_OPPS_DETAILS_API,
  FUNDING_OPPS_REQUEST_CALL,
  FUNDING_OPPS_ROOT_API,
  FUNDING_OPPS_STORE_API_KEY,
  FUNDING_OPPS_TAG,
  FUNDING_OPPS_UPLOAD_NDA,
} from './constants';
import {
  FundingOppItem,
  FundingOppsListParams,
  FundingOppUploadNdaParams,
} from './types';

export const fundingOppsApi = createApi({
  reducerPath: FUNDING_OPPS_STORE_API_KEY,
  baseQuery: baseQuery({ root: QueryRoot.Pa, baseUrl: FUNDING_OPPS_ROOT_API }),
  tagTypes: [FUNDING_OPPS_TAG],
  refetchOnMountOrArgChange: true,
  endpoints: ({ mutation, query }) => ({
    getFundingOpps: query<Response<FundingOppItem[]>, FundingOppsListParams>({
      query: (queryParams) => ({ method: ReqMethod.Get, queryParams }),
      providesTags: [FUNDING_OPPS_TAG],
    }),
    getFundingOppDetails: query<FundingOppItem, number>({
      query: (id) => ({
        method: ReqMethod.Get,
        url: generatePath(FUNDING_OPPS_DETAILS_API, { id }),
      }),
      providesTags: [FUNDING_OPPS_TAG],
    }),
    requestCall: mutation<void, number>({
      query: (id) => ({
        method: ReqMethod.Put,
        url: generatePath(FUNDING_OPPS_REQUEST_CALL, { id }),
      }),
    }),
    uploadNda: mutation<void, FundingOppUploadNdaParams>({
      query: ({ id, file }) => ({
        method: ReqMethod.Put,
        url: generatePath(FUNDING_OPPS_UPLOAD_NDA, { id }),
        body: mapObjToFormData({ nda: file }),
      }),
      invalidatesTags: [FUNDING_OPPS_TAG],
    }),
  }),
});

export const {
  useGetFundingOppsQuery,
  useGetFundingOppDetailsQuery,
  useRequestCallMutation,
  useUploadNdaMutation,
} = fundingOppsApi;
