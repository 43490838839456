import { useMemo } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import ExitIcon from 'src/assets/icons/exit-icon.svg?react';
import EnterIcon from 'src/assets/icons/enter-icon.svg?react';
import { isMyselfInitiator, OfferInitiatorType } from 'src/entities/offers';
import { DataGroup } from 'src/entities/dicts';
import Avatar from '@mui/material/Avatar';
import Tooltip from '../Tooltip';

type Props = {
  group: DataGroup;
  initiatorType: OfferInitiatorType;
};

function TermSheetRowAvatar({ group, initiatorType }: Props) {
  const { bgcolor, icon, title } = useMemo(
    () =>
      isMyselfInitiator(group, initiatorType)
        ? { title: 'Outgoing', icon: ExitIcon }
        : { bgcolor: 'primary.main', title: 'Incoming', icon: EnterIcon },
    [group, initiatorType],
  );

  return (
    <Tooltip title={title}>
      <Avatar sx={{ bgcolor }}>
        <SvgIcon component={icon} />
      </Avatar>
    </Tooltip>
  );
}

export default TermSheetRowAvatar;
