import Stack, { StackProps } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SxProps } from 'src/theme/types';
import { HorizontalTableItem, HorizontalTableRowItem } from './types';

type Props<TItem extends HorizontalTableItem> = StackProps & {
  items?: TItem[];
  rows: HorizontalTableRowItem<TItem>[];
  defaultValue?: string;
};

const rowStyle: SxProps = {
  flexDirection: 'row',
  minWidth: 'fit-content',
  pr: 2,
  ':nth-of-type(odd)': { backgroundColor: 'background.default' },
  ':nth-of-type(even)': { backgroundColor: 'background.paper' },
  '>span': { px: 1.5, textAlign: 'center' },
  '>*': { py: 1.5, width: { xs: '50vw', sm: '15rem' } },
};

const headerStyle: SxProps = {
  backgroundColor: 'inherit',
  textAlign: 'left',
  position: 'sticky',
  left: 0,
  px: 3.5,
};

function HorizontalTable<TItem extends HorizontalTableItem>({
  items,
  rows,
  defaultValue = '–',
  ...rest
}: Props<TItem>) {
  if (!items) {
    return null;
  }
  return (
    <Stack {...rest}>
      {rows.map(({ id, label, formatter }) => (
        <Stack key={String(id)} sx={rowStyle}>
          <Typography variant="subtitle1" sx={headerStyle}>
            {label}
          </Typography>
          {items.map((item) => (
            <span key={item.id}>
              {formatter?.(item[id], item) ||
                (item[id] ? String(item[id]) : defaultValue)}
            </span>
          ))}
        </Stack>
      ))}
    </Stack>
  );
}

export default HorizontalTable;
