import { useGetOffersListQuery } from 'src/entities/offers';
import { DataGroup } from 'src/entities/dicts';
import Stack from '@mui/material/Stack';
import TermSheetItems from './TermSheetItems';
import TermSheetContextContextProvider from './TermSheetContext';
import TermSheetCompare from './TermSheetCompare';
import TermSheetCreateBtn from './TermSheetCreateBtn';

type Props = {
  group: DataGroup;
  id: number;
  canManage?: boolean;
};

function TermSheet(params: Props) {
  const { group, canManage = true } = params;
  const { data, isFetching } = useGetOffersListQuery(params);
  const { activeOffers, allOffersCancelled } = data || {};

  return (
    <TermSheetContextContextProvider {...params}>
      <Stack mb={2}>
        {allOffersCancelled ? (
          <TermSheetCreateBtn group={group} canManage={canManage} />
        ) : (
          <TermSheetCompare group={group} items={activeOffers} />
        )}
      </Stack>
      <TermSheetItems
        canManage={canManage}
        group={group}
        items={activeOffers}
        loading={isFetching}
      />
    </TermSheetContextContextProvider>
  );
}

export default TermSheet;
