import { OfferAcceptanceStatus } from 'src/entities/offers';
import Chip, { ChipProps } from '@mui/material/Chip';

type Props = {
  value: OfferAcceptanceStatus;
};

export const statusMap: Record<
  OfferAcceptanceStatus,
  { color: ChipProps['color']; label: string }
> = {
  [OfferAcceptanceStatus.Accepted]: {
    color: 'primary',
    label: 'Accepted',
  },
  [OfferAcceptanceStatus.CounterOffered]: {
    color: 'info',
    label: 'Counter offered',
  },
  [OfferAcceptanceStatus.Cancelled]: {
    color: 'secondary',
    label: 'Cancelled',
  },
  [OfferAcceptanceStatus.Closed]: {
    color: 'secondary',
    label: 'Closed',
  },
  [OfferAcceptanceStatus.NotSelected]: {
    color: 'secondary',
    label: 'Not Selected',
  },
  [OfferAcceptanceStatus.ImplicitlyAccepted]: {
    color: 'info',
    label: 'Implicitly Accepted',
  },
  [OfferAcceptanceStatus.UnderReview]: {
    color: 'warning',
    label: 'Proposed',
  },
};

function TermSheetAccStatus({ value }: Props) {
  const { color = 'secondary', label } = statusMap[value] || {};
  return (
    <Chip
      sx={{ width: '15rem', typography: 'caption' }}
      size="xs"
      color={color}
      label={label}
    />
  );
}

export default TermSheetAccStatus;
