import { OfferItem } from 'src/entities/offers';
import { DataGroup } from 'src/entities/dicts';
import TermSheetRow from './TermSheetRow';
import ListSkeleton from '../ListSkeleton';
import { DataListWrapper } from '../DataList';
import TermSheetCreateBtn from './TermSheetCreateBtn';

type Props = {
  group: DataGroup;
  items?: OfferItem[];
  loading?: boolean;
  canManage?: boolean;
};

function TermSheetItems({ group, items, loading, canManage }: Props) {
  if (loading || !items?.length) {
    return (
      <ListSkeleton loading={loading}>
        No offers yet
        <TermSheetCreateBtn group={group} canManage={canManage} />
      </ListSkeleton>
    );
  }

  return (
    <DataListWrapper showPaging={false}>
      {items?.map((item) => (
        <TermSheetRow
          key={item.id}
          item={item}
          group={group}
          canManage={canManage}
        />
      ))}
    </DataListWrapper>
  );
}

export default TermSheetItems;
