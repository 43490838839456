import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, QueryRoot, ReqMethod } from 'src/services';
import { generatePath } from 'src/utils';
import { getMakeOfferReqValues, mapOffersData } from './utils';
import {
  OFFERS_ACCEPT_API,
  OFFERS_STORE_KEY,
  OFFERS_TAG,
  OFFERS_API,
  OFFERS_ITEM_API,
  OFFERS_MAKE_COUNTER_OFFER_API,
} from './constants';
import {
  OffersTermSheet,
  OfferReqParams,
  OfferUrlParams,
  OfferBaseUrlParams,
} from './types';

export const offersApi = createApi({
  reducerPath: OFFERS_STORE_KEY,
  baseQuery: baseQuery({ root: QueryRoot.Pa }),
  tagTypes: [OFFERS_TAG],
  endpoints: ({ query, mutation }) => ({
    getOffersList: query<OffersTermSheet, OfferBaseUrlParams>({
      query: (params) => ({
        url: generatePath(OFFERS_API, params),
        method: ReqMethod.Get,
      }),
      transformResponse: mapOffersData,
      providesTags: [OFFERS_TAG],
    }),
    createOffer: mutation<void, OfferReqParams>({
      query: ({ values, ...params }) => ({
        url: generatePath(OFFERS_API, params),
        method: ReqMethod.Post,
        body: { values: getMakeOfferReqValues(values) },
      }),
      invalidatesTags: [OFFERS_TAG],
    }),
    counterOffer: mutation<void, OfferReqParams>({
      query: ({ opportunityId, values, ...params }) => ({
        url: generatePath(OFFERS_MAKE_COUNTER_OFFER_API, params),
        method: ReqMethod.Post,
        body: { opportunityId, values: getMakeOfferReqValues(values) },
      }),
      invalidatesTags: [OFFERS_TAG],
    }),
    updateOffer: mutation<void, OfferReqParams>({
      query: ({ values, ...params }) => ({
        url: generatePath(OFFERS_ITEM_API, params),
        method: ReqMethod.Put,
        body: { values: getMakeOfferReqValues(values) },
      }),
      invalidatesTags: [OFFERS_TAG],
    }),
    acceptOffer: mutation<void, OfferUrlParams>({
      query: (params) => ({
        url: generatePath(OFFERS_ACCEPT_API, params),
        method: ReqMethod.Put,
      }),
      invalidatesTags: [OFFERS_TAG],
    }),
  }),
});

export const {
  useGetOffersListQuery,
  useCreateOfferMutation,
  useCounterOfferMutation,
  useUpdateOfferMutation,
  useAcceptOfferMutation,
} = offersApi;
