export enum BorrowerType {
  PrivateCorporate = 'PRIVATE_CORPORATE',
  PrivateCorporateSponsorBacked = 'PRIVATE_CORPORATE_SPONSOR_BACKED',
  PublicCorporate = 'PUBLIC_CORPORATE',
  PublicCorporateSponsorBacked = 'PUBLIC_CORPORATE_SPONSOR_BACKED',
  GeneralPartner = 'GENERAL_PARTNER',
  LimitedPartner = 'LIMITED_PARTNER',
  PartnershipFund = 'PARTNERSHIP_FUND',
  Government = 'GOVERNMENT',
  Other = 'OTHER',
}

export enum CollateralType {
  Bonds = 'BONDS',
  Crypto = 'CRYPTO',
  Equities = 'EQUITIES',
  LpInterest = 'LP_INTEREST',
  OtherAssets = 'OTHER_ASSETS',
  OtherSecurities = 'OTHER_SECURITIES',
  PrivateAssets = 'PRIVATE_ASSETS',
}

export enum LoanType {
  AssetBasedLending = 'ASSET_BASED_LENDING',
  CreditLines = 'CREDIT_LINES',
  GpFinancing = 'GP_FINANCING',
  LpFinancing = 'LP_FINANCING',
  NavFinancing = 'NAV_FINANCING',
  RevenueBasedLending = 'REVENUE_BASED_LENDING',
  StructuredProduct = 'STRUCTURED_PRODUCT',
  TermLoan = 'TERM_LOAN',
  TradeFinance = 'TRADE_FINANCE',
  Secondary = 'SECONDARY',
  Syndication = 'SYNDICATION',
  SpecialSituation = 'SPECIAL_SITUATION',
  DistressedDebt = 'DISTRESSED_DEBT',
  Other = 'OTHER',
}

export enum Sector {
  Agriculture = 'AGRICULTURE',
  BuildingProducts = 'BUILDING_PRODUCTS',
  BusinessServices = 'BUSINESS_SERVICES',
  Chemicals = 'CHEMICALS',
  CleanEnergy = 'CLEAN_ENERGY',
  Coal = 'COAL',
  Construction = 'CONSTRUCTION',
  ConsumerRetail = 'CONSUMER_RETAIL',
  Education = 'EDUCATION',
  EnergyNaturalResources = 'ENERGY_NATURAL_RESOURCES',
  Fig = 'FIG',
  Healthcare = 'HEALTHCARE',
  IndustrialsManufacturing = 'INDUSTRIALS_MANUFACTURING',
  Infrastructure = 'INFRASTRUCTURE',
  Insurance = 'INSURANCE',
  LifeScience = 'LIFE_SCIENCE',
  MediaCommunications = 'MEDIA_TELECOMS',
  Mining = 'MINING',
  Other = 'OTHER',
  RealEstate = 'REAL_ESTATE',
  Shipping = 'SHIPPING',
  SportEntertainment = 'SPORT_ENTERTAINMENT',
  TechnologySoftware = 'TECHNOLOGY_SOFTWARE',
  Transportation = 'TRANSPORTATION',
}

export enum DataGroup {
  fundingOpps = 'fundingOpportunities',
  loanApps = 'loanApplications',
}

export enum UserRole {
  CreditOfficer = 'CREDIT_OFFICER',
}
