import React, { useContext } from 'react';
import { monthsToYears } from 'src/utils';
import { formatCurrValue } from 'src/entities/currency';
import {
  borrowerTypeLabels,
  loanTypeLabels,
  sectorLabels,
} from 'src/entities/dicts';
import DataListRow, { DataListCell } from 'src/components/DataList';
import { FundingOppItem } from 'src/entities/fundingOpps';
import FundingOppsListItemStatus from './FundingOppsListItemStatus';
import { FundingOppDetailsContext } from './FundingOppDetailsContext';

function FundingOppsListItem({
  id,
  borrowerTypeCode,
  currency,
  loanAmount,
  loanTerm,
  loanTypeCode,
  sectorCode,
  statusCode,
  regionCode,
}: FundingOppItem) {
  const { showFundingOppDetails } = useContext(FundingOppDetailsContext);

  return (
    <DataListRow
      sx={{ cursor: 'pointer' }}
      onClick={() => showFundingOppDetails(id)}
    >
      <DataListCell primary={formatCurrValue(loanAmount, currency)} />
      <DataListCell primary={`${monthsToYears(loanTerm)} years`} />
      <DataListCell
        primary={regionCode}
        secondary={borrowerTypeLabels[borrowerTypeCode]}
      />
      <DataListCell
        primary={sectorLabels[sectorCode]}
        secondary={loanTypeLabels[loanTypeCode]}
      />
      <FundingOppsListItemStatus status={statusCode} />
    </DataListRow>
  );
}

export default FundingOppsListItem;
