import {
  OfferValuesFormParams,
  OfferValue,
  useCounterOfferMutation,
} from 'src/entities/offers';
import { DataGroup } from 'src/entities/dicts';
import { ModalCloseHandler } from '../ModalDialog';
import { AlertType, useAlert } from '../AlertsProvider';
import TermSheetFormModal from './TermSheetFormModal';

type Props = {
  group: DataGroup;
  id: number;
  termSheetId: number;
  opportunityId?: number;
  open: boolean;
  onClose: ModalCloseHandler;
  values?: OfferValue[];
};

function TermSheetCounterOfferModal({
  group,
  id,
  opportunityId,
  termSheetId,
  onClose,
  ...rest
}: Props) {
  const { showAlert } = useAlert();
  const [makeCounterOffer, { isLoading }] = useCounterOfferMutation();

  const onCounterOffer = ({ values }: OfferValuesFormParams) =>
    makeCounterOffer({ group, id, opportunityId, termSheetId, values })
      .unwrap()
      .then(() => {
        showAlert({
          type: AlertType.Success,
          text: 'Term sheet has been counter offered',
        });
        onClose();
      });

  return (
    <TermSheetFormModal
      onClose={onClose}
      onSubmit={onCounterOffer}
      loading={isLoading}
      title="Make counter offer"
      {...rest}
    />
  );
}

export default TermSheetCounterOfferModal;
