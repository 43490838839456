import React, { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { monthsToYears } from 'src/utils';
import { Currency } from 'src/entities/currency';
import { DataGroup, loanTypeLabels } from 'src/entities/dicts';
import {
  LoanAppItemField,
  LoanAppStatus,
  useGetLoanAppDetailsQuery,
} from 'src/entities/loanApps';
import FieldsSet, {
  createCurrFormatter,
  createLabelFormatter,
  FieldDataItem,
} from 'src/components/FieldsSet';
import FilesDataRoom from 'src/components/FilesDataRoom';
import {
  SideModalSkeleton,
  SideModalTabs,
  SideModalTabsList,
} from 'src/components/SideModal';
import { Tab, TabPanel } from 'src/components/Tabs';
import TermSheet from 'src/components/TermSheet';
import { statusMap } from './data';
import LoanAppDetailsCreditors from './LoanAppDetailsCreditors';

type Props = {
  id: number;
};

const getFields = (currency?: Currency): FieldDataItem[] => [
  {
    id: LoanAppItemField.LoanAmount,
    label: 'Loan Amount',
    formatter: createCurrFormatter(currency),
  },
  {
    id: LoanAppItemField.LoanTerm,
    label: 'Loan Term',
    formatter: (value) => `${monthsToYears(value as number)} years`,
  },
  {
    id: LoanAppItemField.RegionCode,
    label: 'Region',
  },
  {
    id: LoanAppItemField.loanTypeCode,
    label: 'Type Of Debt Solution',
    formatter: createLabelFormatter(loanTypeLabels),
  },
  {
    id: LoanAppItemField.AdditionalInfo,
    label: 'AdditionalInfo',
  },
];

function LoanAppDetails({ id }: Props) {
  const [tab, setTab] = useState(0);
  const { data: res, isFetching } = useGetLoanAppDetailsQuery(id);
  const {
    statusCode = LoanAppStatus.Draft,
    currency,
    isBorrowerConfirmationRequired,
    rawdata,
    ...data
  } = res || {};
  const { color, label } = statusMap[statusCode] || {};
  const canUpdateDataRoom =
    statusCode !== LoanAppStatus.Inactive &&
    statusCode !== LoanAppStatus.Rejected &&
    statusCode !== LoanAppStatus.Funded;
  const canManageTermSheet = statusCode === LoanAppStatus.SourcingOfCapital;
  const isTermSheetAvailable =
    statusCode === LoanAppStatus.SourcingOfCapital ||
    statusCode === LoanAppStatus.Funded;

  useEffect(() => {
    setTab(0);
  }, [id]);

  if (!data || isFetching) {
    return <SideModalSkeleton />;
  }
  return (
    <>
      <Chip
        sx={{ maxWidth: '20rem', mb: 2 }}
        size="xs"
        color={color}
        label={label}
      />
      <Typography variant="h6" mb={2}>
        General information
      </Typography>
      <FieldsSet
        sx={{ mb: 2 }}
        rowStyle={{ mx: -2, px: 2 }}
        fields={getFields(currency)}
        data={data}
        zebra
      />
      <SideModalTabs
        value={tab}
        tabsPanel={
          <SideModalTabsList onChange={setTab}>
            <Tab label="Data Room" />
            {isTermSheetAvailable && <Tab label="Term Sheet" />}
            {isBorrowerConfirmationRequired && <Tab label="Debt Investors" />}
          </SideModalTabsList>
        }
      >
        <TabPanel value={0}>
          <FilesDataRoom
            canUpdate={canUpdateDataRoom}
            group={DataGroup.loanApps}
            id={id}
          />
        </TabPanel>
        <TabPanel value={1}>
          <TermSheet
            canManage={canManageTermSheet}
            group={DataGroup.loanApps}
            id={id}
          />
        </TabPanel>
        <TabPanel value={2}>
          <LoanAppDetailsCreditors id={id} loanAppStatusCode={statusCode} />
        </TabPanel>
      </SideModalTabs>
    </>
  );
}

export default LoanAppDetails;
